@import "../../../theme/variables.scss";

.avatar-container {
  display: grid;
  grid-template-rows: 30px 220px 160px auto;
  justify-items: center;
  font-family: $secondaryFontFamily;
  .navigation-icon {
    margin: 0 15px;
    color: $console-title-gray;
    cursor: pointer;
  }
}
.avatar-name {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.avatar-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $secondaryFontFamily;
  color: black;
}
