@import '../../../../theme/variables.scss';

.webhook-form {
  grid-template-rows: 1fr auto;
  display: grid;
  grid-row-gap: 30px;

  .inputs-fields {
    margin-bottom: 30px;
    margin-left: 45px;
    display: grid;
    grid-template-columns: repeat(2, 165px);
    column-gap: 20px;
  }
}

.webhooks-tester-block {
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 15px;
  grid-column-gap: 10px;
  grid-template-areas:
    "title title"
    "test message";
  padding: 20px 0;
  border-top: solid 1px #E8E8E8;

  .webhooks-msg-frame {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 5px;
    align-items: center;
  }

  .icon-check {
    color: #1FBA75;
  }

  .icon-close-popup {
    color: #EB323C;
  }

  .webhook-main-ttl {
    grid-area: title;
  }

  .webhook-test-btn {
    grid-area: test;
    position: relative;
    text-transform: uppercase;
    height: 30px;
  }

  .webhooks-msg-frame {
    grid-area: message;
  }

  .webhooks-msg-frame,
  .webhook-main-ttl {
    font-size: 13px;
  }

  .webhooks-test-loader {
    top: 0;
    right: 5px;
    background: transparent;
    img {
      height: 22px;
    }
  }
}

.add-item-wrapper.url-exclude-list {
  padding: unset;
}

.section:not(.readonly) .url-list-item {
  cursor: pointer;
}

.MuiListItem-root.url-list-item {
  height: auto;
  width: 100%;
  padding: 16px 37px;
  display: block;
  .list-item-content {
    margin-left: 0px;
    padding: 20px 16px 20px 80px;
    position: relative;
    font-size: 14px;
    color: #3d3d3d;
    overflow-wrap: break-word;
  }
  .MuiAvatar-root {
    color: rgb(255, 255, 255);
    background-color: rgb(188, 188, 188);
    user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 3px;
    left: 16px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
  }
}

.auth-checkbox-control {
  margin-bottom: 20px;
}

.webhook-customize {
  &-control {
    margin-top: -10px;
  }

  &-label {
    font-family: $primaryFontFamily;
    color: #505050;
  }
}

.collapse-content {
  height: auto;
  margin-left: 25px;
  .submit-button {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 10px 32px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.webhook-list-item-wrapper {
  .basic-auth-content {
    position: relative;
    left: 20px;
    margin-bottom: 10px;
  }
  
  .payloadWrap {
    position: relative;
    top: -20px;
    margin-left: 115px;
  }

  .url-error {
    position: inherit;
    margin-bottom: 20px;
    text-align: right;
    font-size: 14px;
  }
}

.api-key-section {
  display: flex;
  padding: 30px 15px;
  button {
    color: rgb(80, 80, 80);
  }
}

.api-key {
  text-align: center;
  color: #787878;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  line-height: 1.93;

  .copy-icon {
    color: #0091ff;
    cursor: pointer;
    margin-left: 6px;
  }
}
