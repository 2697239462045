@import '../../../../theme/variables.scss';

.create-form.ads-rev-share-form {
  align-items: flex-end;

  .fields-grid {
    width: 100%;
    padding-bottom: $settings-sections-padding;
  }

  .buttons-wrapper {
    margin-top: 0;
  }  

  .outlined-secondary {
    padding: 7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}
