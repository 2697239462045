@import '../../../theme/variables.scss';

.channel-form {
  .revenue-type-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 16px;
  
    .rev-share-field {
      width: 56px;
      height: 24px;
      border-bottom: 1px solid $neutral100;
  
      &:before,
      &:after {
        border-color: transparent;
      }
  
      &:focus-within,
      &:hover {
        &:before,
        &:after {
          border-bottom: 1px solid black;
        }
      }
  
      input::-webkit-inner-spin-button {
        display: none;
      }
  
      .MuiInputAdornment-positionEnd {
        margin-left: 0;
      }
  
      .MuiInputAdornment-positionStart {
        margin-right: 2px;
      }
  
      input {
        padding: 2px 0;
      }
  
      input, .MuiInputAdornment-root p {
        font-size: 14px;
        line-height: 24px;
        color: $neutral700;
      }
    }
  }  
}

.plan-field.trial-ended {
  .current-value::after {
    content: '(ended)';
    position: absolute;
    margin-left: 5px;
    color: $primary500;
  } 
}

.actionable-field {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 16px;
  align-items: flex-start;

  &.no-action {
    grid-template-columns: 1fr;
  }

  .trial-actions {
    grid-auto-flow: column;
    display: grid;
    align-self: center;
    align-items: center;
  }

  button {
    color: $neutral800;
    font-size: 20px;
    border-radius: 4px;
    padding: 4px;
    height: 40px;
    width: 40px;
    .MuiSvgIcon-root {
      font-size: 20px;
    }
    &.contained-primary {
      padding: 0px 8px;
      width: 65px;
      height: 32px;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.link {
  color: $secondary400;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  align-self: center;
  &:hover {
    color: $secondary500;
  }
}

.domains-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1E1E37;
  margin: 20px 0 20px
}

.domains-wrapper {
  margin-left: 24px;
  margin-right: 8px;

  .domains-list {
    max-height: 316px;
    overflow-y: auto;
    display: grid;
    row-gap: 15px;
  }

  .limit-error {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $primary500;
    border-bottom: 1px solid $neutral100;
    width: calc(100% - 52px);
  }

  .domain-item {
    display: grid;
    grid-template-columns: 1fr 36px;
    column-gap: 16px;
    align-items: flex-start;
  }

  .add-domain {
    margin-top: 20px;
    font-size: 13px;
    line-height: 16px;
    padding: 8px 16px;
    justify-self: flex-start;
    & .MuiButton-startIcon {
      margin-left: -5px;
      margin-right: 5px
    }
    & .MuiButton-label {
      display: flex;
    }
  }
}

.delete-domain {
  color: $neutral900;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    background: #F4F4F5;
  }
  &:active {
    background: #E8E8EB;
  }
  & .MuiSvgIcon-root {
    font-size: 20px;
  }
}
