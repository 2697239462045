@import "../../../../theme/variables.scss";

$color-indication-size: 20px;

.colors-form {
  button.outlined-secondary {
    padding: 7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    box-sizing: border-box;
    width: fit-content;
  }
  .colors-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.palette-grid {
  display: grid;
  grid-auto-columns: auto;
  align-items: baseline;
  padding: 0 $settings-sections-padding;
  color: $neutral900;
  font-family: $secondaryFontFamily;
  row-gap: 39px;
}

.color-input-wrapper {
  font-size: 14px;
  align-items: center;
  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr $color-indication-size;

  &.actionable {
    grid-template-columns: 1fr $color-indication-size 72px;
  }

  .delete-button {
    border-radius: 4px;
    color: $neutral900;
    padding: 6px;
    svg {
      font-size: 20px;
    }
  }
}

.color-preview {
  border-radius: 3px;
  transition: all 0.3s ease;
  border: 1px dashed $neutral200;
  height: $color-indication-size;
  width: $color-indication-size;
  &.progress-bar {
    margin-left: 8px;
  }
}

.colors-wrapper {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-row-gap: 35px;
  grid-column-gap: 40px;
  column-gap: 40px;
}
