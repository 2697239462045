@import '../../../../../../theme/variables.scss';

.payments-box {
  box-shadow: 0 9px 21px 0 rgba(152, 153, 155, 0.28);
  background-color: #ffffff;
  position: relative;
  font-family: $secondaryFontFamily;
  width: 729px;
  .payments-box-header {
    width: 100%;
    height: 38px;
    background-color: #f7f7f7;
    opacity: 0.5;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #505050;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    span {
      margin-left: 30px;
    }
  }
  .data-container {
    padding: 40px;
    font-family: $secondaryFontFamily;
    .content {
      display: flex;
      flex-direction: row;
      position: relative;
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #4a4a4a;
        display: flex;
        flex-direction: column;
        .success-icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #06bb72;
          position: relative;
          display: flex;
          justify-content: center;
          &::after {
            content: "";
            position: absolute;
            border-bottom: 2px solid white;
            z-index: 999;
            height: 3px;
            width: 7px;
            border-left: 2px solid white;
            border-bottom: 2px solid white;
            transform: rotate(315deg);
            top: 5px;
          }
        }
      }
      .description {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.93;
        letter-spacing: normal;
        color: #4a4a4a;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        .description-text {
          width: 486px;
        }
      }
      .btn-and-image-container {
        display: flex;
        flex-direction: column;
        .gif-container {
          display: flex;
          align-items: flex-end;
          flex-direction: row;
          align-items: flex-end;
          img {
            height: 120px;
          }
        }
        .image-container {
          img {
            width: 158px;
            height: 158px;
            object-fit: contain;
          }
        }
        .btn-container {
          display: flex;
          justify-content: center;
          margin-top: 26px;
          .btn-done {
            cursor: pointer;
            font-family: $secondaryFontFamily;
            width: 122px;
            height: 40px;
            border-radius: 4px;
            border: solid 1px #06bb72;
            background-color: #06bb72;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.12;
            letter-spacing: 2px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
}
