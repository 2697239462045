@import '../../../theme/variables.scss';

.table-wrapper {
  .table {
    overflow: auto;
    max-height: 318px;
  }

  .status-label {
    border-radius: 4px;
    border: 1px solid;
    font-family: $primaryFontFamily;
    font-weight: 300;
    color: black;
    text-align: center;
    padding: 3px 7px;
    width: fit-content;

    &.pending {
      background-color: $primary50;
      border-color: $primary300;
    }

    &.registered {
      background-color: #F0F8FF;
      border-color: #66B6FF;
    }
  }

  .role-label {
    color: $neutral700;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }

  .resend-button {
    cursor: pointer;
    color: $secondary400;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }

  &.view-mode {
    margin-top: $settings-sections-padding;

    .header-row th:first-child,
    .body-row td:first-child {
      padding-left: 15px;
    }
  }
}
