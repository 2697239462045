// COLORS

$console-title-gray: rgba(90, 90, 90, 0.7);
$neutral50: #F4F4F5;
$neutral100: #E8E8EB;
$neutral200: #D2D2D7;
$neutral300: #BCBCC3;
$neutral400: #A5A5AF;
$neutral500: #8E8E9B;
$neutral600: #616173;
$neutral700: #4B4B5F;
$neutral800: #35354B;
$neutral900: #1E1E37;
$primary50: #FEF5F5;
$primary100: #F9C1C5;
$primary300: #F17077;
$primary500: #EB323C;
$primary600: #D32D36;
$secondary400: #0082FA;
$secondary500: #0075E1;

// SIZES

$trial-notification-height: 56px;
$header-height: 60px;
$overview-height: 200px;
$blocking-top: 10%;
$settings-sections-padding: 24px;
$settings-sections-gap: 12px;
$settings-sections-label-padding: 4px;

// ICON SIZES

$smallIcon: 16px;
$smallIconButtonPadding: 8px;
$mediumIcon: 20px;
$mediumIconButtonPadding: 10px;
$largeIcon: $mediumIcon;
$largeIconButtonPadding: 12px;

// TYPOGRAPHY

$primaryFontFamily: Roboto, sans-serif;
$secondaryFontFamily: LatoLatin, sans-serif;

@mixin baseExtraSmallRegular {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

@mixin baseSmallRegular {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
}

@mixin baseMediumSemiBold {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

@mixin baseExtraSmallSemiBold {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

@mixin baseSmallSemiBold {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

@mixin baseMediumExtraBold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

@mixin baseMediumRegular {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}

@mixin baseMediumExtraRegular {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

@mixin baseRegular {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

$icomoon-font-family: "Apester-Console-Font" !default;
$icomoon-font-path: "./fonts/apester" !default;

$icon-expiration-date: unquote('"\\e92d"');
$icon-auto-poll: unquote('"\\e92c"');
$icon-delete-outlined: unquote('"\\e92b"');
$icon-sort: unquote('"\\e92a"');
$icon-file-download: unquote('"\\e929"');
$icon-warning: unquote('"\\e928"');
$icon-refresh: unquote('"\\e927"');
$icon-light: unquote('"\\e926"');
$icon-calendar-filled: unquote('"\\e925"');
$icon-image: unquote('"\\e924"');
$icon-custom: unquote('"\\e923"');
$icon-long-curvy-arrow: unquote('"\\e921"');
$icon-close-popup: unquote('"\\e922"');
$icon-story-thin: unquote('"\\e920"');
$icon-alert: unquote('"\\e91f"');
$icon-alert-new: unquote('"\\e91e"');
$icon-star: unquote('"\\e91d"');
$icon-edit-pencil: unquote('"\\e91c"');
$icon-view: unquote('"\\e91b"');
$icon-trash: unquote('"\\e91a"');
$icon-user-profile: unquote('"\\e919"');
$icon-countdown: unquote('"\\e913"');
$icon-gallery: unquote('"\\e914"');
$icon-personality: unquote('"\\e915"');
$icon-poll: unquote('"\\e916"');
$icon-story: unquote('"\\e917"');
$icon-video: unquote('"\\e918"');
$icon-embed: unquote('"\\e912"');
$icon-Lock: unquote('"\\e904"');
$icon-Iphone: unquote('"\\e905"');
$icon-hamburger: unquote('"\\e906"');
$icon-text-l: unquote('"\\e907"');
$icon-check: unquote('"\\e908"');
$icon-apester: unquote('"\\e909"');
$icon-arrow-right: unquote('"\\e90a"');
$icon-arrow-left: unquote('"\\e90b"');
$icon-DrowDown: unquote('"\\e90c"');
$icon-search: unquote('"\\e90d"');
$icon-cross: unquote('"\\e90e"');
$icon-plus: unquote('"\\e90f"');
$icon-inative: unquote('"\\e902"');
$icon-programmatic: unquote('"\\e903"');
$icon-csv: unquote('"\\e900"');
$icon-calendar: unquote('"\\e901"');
$icon-performances: unquote('"\\e910"');
$icon-editorial: unquote('"\\e911"');