@import "../../../theme/variables.scss";

.toggle-form-control {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-left: -3px;
  width: fit-content;

  &.Mui-disabled {
    .switch-base + .track {
      background-color: $neutral200;
    }
  }

  .MuiFormControlLabel-label {
    color: $neutral900;
    font-family: $primaryFontFamily;
    &.baseMediumExtraBold {
      @include baseMediumExtraBold;
    }
    &.baseMediumExtraRegular {
      @include baseMediumExtraRegular;
    }
  }

  &.no-label .switch-root {
    margin-right: 0;
  }

  &.large {
    .switch-root {
      .switch-checked {
        margin-left: -2px;
      }
      .thumb {
        width: 14px;
        height: 14px;
      }
      .track {
        height: 18px;
        width: 36px;
      }
    }
  }

  &.medium {
    .switch-root {
      .switch-checked {
        margin-left: -6px;
      }
      .thumb {
        width: 12px;
        height: 12px;
      }
      .track {
        height: 16px;
        width: 30px;
      }
    }
  }

  .switch-base {
    padding: 5px;

    & + .track {
      background-color: $neutral500;
    }

    &:hover {
      background-color: transparent;
      & + .track {
        background-color: $neutral600;
      }
    }

    &.switch-checked.Mui-disabled {
      & + .track {
        background-color: $primary100;
      }
    }

    &.switch-checked:not(.Mui-disabled) {
      & + .track {
        background-color: $primary500;
      }

      &:hover {
        background-color: transparent;
        & + .track {
          background-color: $primary600;
        }
      }
    }
  }

  .track {
    background-color: $neutral500;
  }

  .switch-root {
    height: auto;
    width: auto;
    padding: 3px;
    margin-right: 8px;
    .thumb {
      background-color: #FFFFFF;
      box-shadow: none;
    }
    .track {
      border-radius: 100px;
      opacity: 1;
    }
  }
}
