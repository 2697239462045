@import '../../../../theme/variables.scss';

.headers-containers {
  text-align: left;
  display: inline-block;
  width: 70%;

  .headers {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;

    .step-number {
      font-family: $secondaryFontFamily;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      color: #3d3d3d;
      margin: 0px 23px 0;
    }

    .step-title {
      font-family: $secondaryFontFamily;
      font-size: 16px;
      color: #3d3d3d;
      margin: 0px 23px 0;
    }
  }
}
