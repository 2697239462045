
.plan-name{
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  align-items: center;

}
    .plan-checked {
      width: 22px;
      height: 22px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      margin-right: 8px;
      div{
        display: flex;
      }
    }