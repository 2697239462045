@import '../../../../theme/variables.scss';

.description-section {
  display: grid;
  grid-template-rows: 50px 1fr;
  width: 100%;
  justify-items: center;
  padding-bottom: 25px;

  .description-title {
    width: 51%;
  }
}

.create-form .select-field.auth-select-field {
  align-items: center;
  text-align: left;
  padding-right: 14px;
  display: grid;
  margin: auto;
  width: 231px;
  box-sizing: border-box;
  margin-bottom: 10px;

  .custom-select-field {
    padding-left: 14px;
    border-bottom: none;
    height: 100%;
    height: 43px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
    display: flex;
    align-items: center;

    .text-wrapper {
      font-size: 12px;
      color: #505050;
      font-family: $primaryFontFamily;
      line-height: 15px;
    }
  }

  .custom-select-icon {
    margin-right: 10px;
    margin-top: 2px;
  }

  hr {
    border: none;
  }
}

.auth-select-menu .MuiPaper-root {
  width: 230px;
}
