@import '../../theme/variables.scss';

.breadcrumbs-container {
  .link-item, .active-link {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
  .link-item {
    color: $neutral800;
    text-decoration: underline;
    &:hover {
      color: #000;
    }
  }
  .active-link {
    color: $neutral600;
  }
  .MuiBreadcrumbs-separator {
    svg {
      font-size: 18px;
      color: $neutral200;
    }
  }
  .PrivateBreadcrumbCollapsed-root-5 {
    background-color: transparent;
    color: $neutral800;
    margin: 0;
    svg {
      width: 11px;
      height: 16px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
