@import '../../../../../theme/variables.scss';

.manage-plan-container{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 730px;
}

.plan-details{
  width: 400px;
  height: 57px;
  font-family: $secondaryFontFamily;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: #787878;
}

.payment-details{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $secondaryFontFamily;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: #4a4a4a;
}

.details-container2{
  display: flex;
  height: 200px;
}

.card-details{
  height: 50px;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  transition: background-color .2s ease;
}

.card-details:hover{
  background-color: #efefef
}

.details-title{
  border-bottom: solid 1px #6432ff;
  width: fit-content;
}