@import '../../../../theme/variables.scss';

.custom-text-field {
  height: 100%;
  .MuiInputBase-root {
    padding: 6px 10px;
    height: 100%;
    border-color: $neutral200;
    &.Mui-focused fieldset {
      border: 1px solid $neutral900;
    }
  }

  .MuiInputAdornment-root {
    margin-left: 5px;
  }

  .field-icon {
    color: $neutral900;
    font-size: $mediumIcon;
    &.clear {
      cursor: pointer;
    }
  }

  input {
    height: auto;
    padding: 0;
    font-family: $secondaryFontFamily;
    font-weight: 400;
    font-size: 13px;
  }
}
