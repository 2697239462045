@import '../../theme/variables.scss';

.ellipsis-tooltip {
  width: 100%;
  overflow: hidden;
}

.text-wrapper {
  @include ellipsis;
}
