@import "../../theme/variables.scss";

.loader-wrapper {
  height: 100%;
  width: 100%;
  background: rgba(256, 256, 256, 0.65);
  z-index: 10;
  position: absolute;
  &.full-screen-loader{
    top: $overview-height;
    z-index: 15;
    .has-notification &{
      margin-top: #{$trial-notification-height};
    }
  }
}

.loader {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
