@import '../../../../theme/variables.scss';

.channel-adtsxt-container {
  .form-content {
    padding: 22px 24px 32px 24px;
    display: flex;
    flex-direction: column;
  }

  .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .information {
    display: flex;
    justify-content: flex-end;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #808080;
  }

  .ad-list {
    height: 296px;
    overflow: auto;
    border-radius: 4px 4px 0 0;
    border: 1px solid #CCCCCC;
    padding: 15px 10px 15px 28px;
    margin: 16px 0;
    opacity: 1;
    transition: opacity 0.3s ease;

    .item {
      margin: 2px 0;
      color: #404040;
      @include baseMediumRegular;

      &:not(.marked) {
        list-style-type: none;
      }

      &::marker {
        color: $primary500;
      }
    }

    &.disabled {
      user-select: none;
      cursor: default;
      opacity: 0.5;
    }
  }

  .actions-wrapper {
    display: grid;
    grid-auto-flow: column;
    column-gap: 20px;
    justify-content: flex-end;

    button {
      width: fit-content;

      .ic, svg {
        color: inherit;
        font-size: 24px;
      }
    }
  }
}
