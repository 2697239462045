.method-container {
  width: 729px;
  height: 165px;
  margin-top: 50px;
  box-shadow: 0px 11px 21px 0 #e3e3e4;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease;
  &.box-expends {
    height: 400px;
  }
  .container-header {
    width: 729px;
    height: 38px;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    span {
      color: #a4a4a4;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2px;
      padding-left: 35px;
    }
  }
  .method-content {
    display: flex;
    width: 71%;
    height: 84px;
    justify-content: space-between;
    justify-content: center;
    margin: 25px 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    opacity: 1;
    transition: opacity 0.3s ease, height 0.3s ease;
    &.hidden {
      height: 0;
      opacity: 0;
    }
    .credit-box {
      padding: 0 10px;
      height: 57px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.09);
      background-color: #ffffff;
      display: inline-flex;
      flex-direction: row;
      border-radius: 2px;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s linear;
      &:hover {
        background-color: #78787854;
      }
      img {
        width: 23px;
        height: 16px;
        object-fit: contain;
        transform: translateY(-50%);
      }
      .credit-inner-box {
        display: inline-flex;
        flex-direction: column;
        margin-left: 6px;
        font-size: 14px;
        font-weight: bold;
        color: #787878;
        .card-number {
          color: #787878;
          display: inline-flex;
          align-items: center;
          font-weight: bold;
          justify-content: space-between;
          .card-hidden-number {
            margin-left: 4px;
            font-weight: normal;
            color: #787878;
          }
        }
        .card-expiration {
          margin-top: 6px;
          opacity: 0.44;
          font-size: 10px;
          font-weight: normal;
          color: #787878;
        }
      }
    }
    .action {
      height: 28px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.25;
      letter-spacing: normal;
      color: #06bb72;
      position: relative;
      cursor: pointer;
      display: flex;
      width: 35%;

      &.cancel-plan-danger {
        color: red;
      }
      a {
        text-decoration: none;
        color: #06bb72;
      }
      &::after {
        content: " ";
        font-size: 14px;
        margin: -2px 5px;
        height: 4px;
        width: 4px;
        border-top: 1px solid #06bb72;
        border-left: 1px solid #06bb72;
        display: flex;
        align-items: center;
        align-self: center;
        flex-direction: row;
        transform: rotate(133deg);
        align-content: center;
      }
      &.btn-disabled {
        color: #787878;
        cursor: not-allowed;
        &::after {
          border-top: 1px solid #787878;
          border-left: 1px solid #787878;
        }
      }
    }
  }
}
