@import '../../../../../theme/variables.scss';

.strip-wrapper {
  height: 280px;
  overflow: hidden;

  .strip-element{
    width: 98%;
    margin: 0 auto;
    margin-top: 71px;
  }
  .glider-slide {
    min-width: 128px;
  }
  .glider-next {
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    background-color: white;
    background-image: url('../../../../../assets/images/arrow-right.svg');
    background-size: 13px;
    cursor: pointer;
    margin-top:15px;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    font-family: sans-serif;
    background-position: center;
    font-size: 0;
    &.disabled{
      opacity: 0!important;
    }
  }
  .glider-prev {
    width: 44px;
    height: 44px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    background-color: white;
    background-image: url('../../../../../assets/images/arrow-right.svg');
    background-size: 13px;
    transform: scaleX(-1) rotateZ(0) translateY(-50%);
    -ms-filter: 'FlipH';
    font-size: 0;
    filter: FlipH;
    cursor: pointer;
    margin-top:15px;
    left: 0;
    position: absolute;
    background-repeat: no-repeat;
    font-family: sans-serif;
    background-position: center;
    &.disabled{
      opacity: 0!important;
    }
  }
  .story-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      .image-item-wrapper > div > div{
        background-color: #555555;
        &:before{
          color: #c7c7c7;
          left: 50%; 
          top: 50%; 
          transform: translate(-50%, -50%); 
          position: absolute;
          font-family: $secondaryFontFamily;
          font-size: 13px;
        }
        &.item-no-image:before{
          content: '+ Add';
        }
      }
      .item-image {
        .icon-edit-pencil{
          z-index: 333;
          display: block;
        }
        .image div{
          filter: opacity(0.54);
          background-color: #383838;
          height: 100%;
          width: 100%;
        }
        .image div:before{
          content: 'Edit';
          font-size: 13px;
          position: absolute;
          color: #c7c7c7;
          right: 50px;
          left: 50%; 
          top: 50%; 
          transform: translate(-50%, -50%);
        }
      } 
      .image-round{
        &.dashed{
          border: 1px solid #C6C6C5;
        }
      }
      .text-item{
        color: #808080;
      }
    }  
 
  }
}