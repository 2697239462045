@import '../../../../../theme/variables.scss';

.go-back-btn {
  display: flex;
  flex-direction: row;
  justify-content: right;
  cursor: pointer;
  .title {
    opacity: 0.5;
    font-family: $secondaryFontFamily;
    font-size: 14px;
    color: #4a4a4a;
    margin-left: 6px;
  }
}
