@import '../../../theme/variables.scss';

.table-wrapper {
  .table {
    overflow: auto;
    max-height: 318px;
  }

  .header-row th.rev-share-header-column {
    text-align: center;
    padding-right: 0;
  }

  .rev-share-column {
    padding: 0 10px;

    input {
      text-align: left;
    }

    .select-wrapper {
      text-align: right;
    }
  }

  .rev-share {
    align-items: center;
    display: grid;
    grid-template-columns: 97px auto;
    column-gap: 8px;

    .rev-share-field {
      align-items: baseline;
      width: fit-content;

      &:before,
      &:after {
        border-color: transparent;
      }

      &:focus-within {
        &:before,
        &:after {
          border-bottom: 1px solid black;
        }
      }

      input::-webkit-inner-spin-button {
        display: none;
      }

      .MuiInputAdornment-positionEnd {
        margin-left: 0;
      }

      .MuiInputAdornment-positionStart {
        margin-right: 2px;
      }

      input, .MuiInputAdornment-root p {
        color: $neutral700;
        @include baseExtraSmallRegular;
      }
    }
  }
}

.unassign-member-error {
  width: max-content;
  max-width: 90%;
}

.more-actions-menu {
  .list-item-button {
    @include baseSmallRegular;
    color: $neutral900;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    height: 40px;
    svg {
      font-size: 16px;
    }
  }
}
