@import '../../../theme/variables.scss';

$tabs-container-height: 30px;

.skeleton-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  font-family: $primaryFontFamily;
  overflow: hidden;

  .screen-profile {
    border-right: solid 1px $neutral100;
    height: calc(100% - 30px);
  }

  .section-content {
    padding-bottom: $settings-sections-padding;
    padding-top: $settings-sections-padding;
  }

  .form-title {
    font-size: 12px;
    color: $neutral500;
    background-color: $neutral50;
    padding: 0 $settings-sections-padding;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    justify-content: space-between;

  }

  .screen-settings {
    overflow: hidden;
    height: 100%;
    position: relative;
  }

  .profile-sections {
    display: grid;
    row-gap: 45px;
    grid-template-rows: min-content;
    align-items: flex-start;
  }

  .tabs-container, .profile-sections {
    max-width: 750px;
  }

  .screen-settings {
    overflow-y: hidden;
    height: 100%;
    position: relative;

    .section {
      display: grid;
      grid-template-rows: 32px auto;
      border: 1px solid $neutral100;
      background-color: #ffffff;

      .form-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr auto;
        align-items: center;
        grid-row-gap: 30px;
        padding: $settings-sections-padding;
      }

      .country-select-group {
        display: grid;
        grid-template-columns: 75px 1fr;
        column-gap: 10px;
        align-items: center;
        height: 55px;

        .select-field {
          width: 100%;
          padding-bottom: 19px;
        }
      }
    }
  }

  .profile-sections-wrapper {
    overflow-y: auto;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    padding-right: 20px;
    padding-left: 8%;
  }

  .screen-settings.has-tabs {
    .profile-sections-wrapper {
      height: calc(100% - 95px);
    }
    .profile-sections-wrapper {
      margin-top: 65px;
    }
  }

  .tabs-container {
    margin-right: 20px;
    margin-left: 8%;
    right: 0;
    left: 0;
    z-index: 9;
    position: absolute;
    background: white;
    height: $tabs-container-height;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $neutral100;

    button {
      border: none;
      font-family: $secondaryFontFamily;
      color: $neutral500;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.18em;
      border-bottom: 4px solid transparent;
      transition: all 0.2s ease;

      &:disabled {
        color: $neutral300;
      }

      &:focus {
        outline: 0;
      }

      &.active {
        border-color: $primary500;
        color: $neutral900;
      }
    }
  }
}
