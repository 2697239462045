@import '../../../theme/variables.scss';

.create-form {
  padding: 5px $settings-sections-padding;
  font-family: $secondaryFontFamily;

  .fields-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-row-gap: 35px;
    column-gap: 40px;
  }

  .buttons-wrapper {
    display: grid;
    grid-auto-flow: column;
    column-gap: 20px;
    margin-top: 49px;
    justify-content: end;
  
    button {
      padding: 10px 32px;
      font-size: 12px;
      line-height: 16px;
    }
    
    .delete {
      &:not(:disabled) {
        color: $primary500;
      }
    }
  }

  .select-field {
    width: 100%;
    overflow: hidden;
  }

  .custom-select {
    width: 100%;
    .custom-select-field {
      transition: all 0.3s linear;
      font-weight: 400;
      font-size: 14px;
      font-family: inherit;
    }
    &.invalid .custom-select-field {
      border-color: $primary500;
    }
  }

  .input-field {
    input,
    .input {
      border-bottom: 1px solid $neutral100;
      width: calc(100% - 4px);
    }
  }

  .input-field, .custom-select-field, .select-field {
    position: relative;
    &.has-prefix {
      input, .input {
        padding-left: 15px;
        width: calc(100% - 17px);
      }
    }

    input,
    .input {
      color: $neutral900;
      transition: all 0.3s linear;
      font-family: inherit;
      font-size: 14px;
      height: 20px;

      &.invalid {
        border-color: $primary500;
      }
    }

    .helper-text {
      height: 14px;
      padding-top: 3px;
      font-size: 12px;
      color: $neutral500;
      display: grid;
      grid-template-columns: repeat(2, max-content);
      column-gap: 4px;
    }

    .invalid-data {
      color: $primary500;
    }

    .prefix {
      position: absolute;
      top: 3px;
      color: $neutral900;
    }
  }

  input {
    @include ellipsis;
  }

  input, .current-value, .custom-select-field {
    &::placeholder, &.empty{
      color: $neutral500;
    }
  }

  .custom-select {
    .custom-select-field {
      border-bottom: 1px solid $neutral100;
      justify-content: space-between;
  
      .current-value {
        padding-right: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        &:not(.empty) {
          color: $neutral900;
        }
      }
    }

    .custom-select-icon {
      margin-top: 6px;
      color: $neutral900;
    }
  }

  .custom-checkbox .MuiFormControlLabel-label {
    @include baseMediumExtraRegular;
    color: $neutral900;
  }  
}

.form-inner-title {
  padding: 0 $settings-sections-padding 32px $settings-sections-padding;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: $neutral900;
}

input {
  border: none;
  outline: none;
  height: 20px;
  background: transparent;
}
