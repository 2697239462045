@import '../../../theme/variables.scss';

.table-actions-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin-bottom: 16px;
  padding-left: 8px;

  .action-items-container {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 10px;
  }

  button, .number-of-selected {
    @include baseSmallRegular;
    font-family: $secondaryFontFamily;
    color: $neutral900;
  }

  button {
    padding: 8px 12px;
  }
}
