@import '../../theme/variables.scss';

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.48);

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: -10px 21px 21px 0 rgba(50, 51, 54, 0.48);
    display: grid;
    grid-template-rows: 47px 1fr;
    justify-content: center;
    align-items: center;
    text-align: center;

    .modal-header {
      display: flex;
      width: inherit;
      border-bottom: solid 1px #eeeeee;
      height: 100%;
      align-items: center;
      justify-content: center;

      .title-string {
        font-family: $secondaryFontFamily;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.62;
        letter-spacing: 1.72px;
        color: #cacaca;
      }

      .close-button {
        position: absolute;
        right: 3%;
        border: none;
        font-size: 16px;
        color: #5f5f5f;
        z-index: 1;
        cursor: pointer;
        &:focus {
          outline: 0;
        }
      }
    }

    .modal .subheader-title {
      font-family: $secondaryFontFamily;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #505050;
      padding-bottom: 25px;
    }
  }
}
