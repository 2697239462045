@import '../../../../../../theme/variables.scss';

.plan-feature-container {
  width: 583px;
  height: 652px;
  border-radius: 4px;
  box-shadow: -10px 21px 21px 0 rgba(50, 51, 54, 0.48);
  background-color: #ffffff;
  position: relative;
  overflow: auto;
  font-family: $secondaryFontFamily;
  .feature-header {
    font-family: $secondaryFontFamily;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 28px;
    margin-bottom: 20px;
    .left {
      display: flex;
      width: 165px;
      justify-content: space-between;
      align-items: center;
      .feature-title {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.3px;
      }
    }
    .views-limit {
      font-size: 12px;
      font-weight: normal;
      color: #969696;
    }
  }
  .plan-details {
    height: 85%;
    width: 100%;
    font-family: $secondaryFontFamily;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    color: #787878;
    .feature-description-container {
      display: flex;
      flex-direction: column;
      .feature-title {
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        color: #4a4a4a;
        margin-left: 40px;
        margin-top: 20px;
        margin-bottom: 8px;
        text-align: left;
      }
      .feature-description {
        height: 52px;
        background-color: #fafafa;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        color: #969696;
        font-size: 12px;
        &.break-line {
          &::after {
            content: "";
            border-top: 1px solid #d8d8d8;
            position: absolute;
            width: 87%;
            text-align: center;
            align-self: center;
            align-items: center;
            margin-left: 40px;
            top: -2px;
          }
        }
        span {
          margin-left: 40px;
        }
        .checkmark-feature {
          margin-right: 40px;
          display: flex;
        }
      }
    }
  }
}
