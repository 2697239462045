.confirm-modal-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  .confirm-modal-text {
    width: 307px;
    line-height: 1.57;
    font-weight: normal;
    color: #505050;
    font-size: 14px;
    margin-top: 17px;
    .sub-title {
      font-weight: bold;
      font-size: 16px;
      margin-top: 24px;
    }
  }
}

.modal-actions {
  display: flex;
  align-items: center;
  margin-top: 45px;
  font-size: 12px;
  font-weight: bold;
  .btn {
    width: 181px;
    height: 43px;
    border-radius: 21.5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .confirm {
    @extend .btn;
    background-color: #ee2e3d;
    color: #ffffff;
  }

  .cancel {
    @extend .btn;
    background-color: #ffffff;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #979797;
    color: "#505050";
    margin-left: 15px;
  }
}
