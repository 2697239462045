@import '../../../theme/variables.scss';

.add-item-container {
  display: flex;
  position: relative;
  align-items: center;
  .search-box {
    transition: all 0.3s linear;
    width: 100%;
    height: 37px;
    border-radius: 21.5px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    padding-left: 24px;
    font-family: $secondaryFontFamily;
    font-weight: 300;
    font-size: 14px;

    &:focus {
      outline: 0;
    }
  }
  .icon-wrapper {
    transition: all 0.3s linear;
    position: absolute;
    right: 0;
    width: 42px;
    height: 42px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiSvgIcon-root {
      font-size: 20px;
      color: $neutral900;
    }
    &.clickable {
      cursor: pointer;
    }
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    .search-box, .icon-wrapper {
      box-shadow: none;
      border-color: $neutral50;
    }
    input, input::-webkit-input-placeholder, .MuiSvgIcon-root {
      color: $neutral300;
    }
  }
}

.add-item-error {
  position: absolute;
  top: 8px;
  left: 40px;
  color: #f13346;
  font-size: 12px;
}

.add-item-wrapper {
  padding: 5px 30px 30px;
  position: relative;
}
