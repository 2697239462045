@import '../../../theme/variables.scss';

$smallFontSize: 13px;
$mediumFontSize: 14px;
$largeFontSize: 16px;
$selectIconSize: 16px;

.custom-select {
  .custom-select-field {
    color: $neutral700;
    font-weight: 300;
    display: flex;
    cursor: pointer;

    .empty-field {
      display: grid;
      grid-auto-flow: column;
      color: $secondary400;
      svg {
        height: 16px;
        width: 16px;
      }
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      color: $neutral400;
    }
  }

  .custom-select-label {
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
    color: $neutral500;
  }

  .custom-select-icon {
    height: $selectIconSize;
    width: $selectIconSize;
    margin-top: 3px;
    color: $neutral500;
    margin-right: 0;
    .MuiSvgIcon-root {
      width: $selectIconSize;
      height: $selectIconSize;
      font-size: $selectIconSize;
    }
  }

  &.small .custom-select-field  {
    font-size: $smallFontSize;
  }

  &.medium .custom-select-field  {
    font-size: $mediumFontSize;
  }

  &.large .custom-select-field {
    font-size: $largeFontSize;
  }

  &.single-select {
    .custom-select-field {
      width: 100%;
      position: relative;
    }

    input {
      left: 0;
      width: 100%;
      bottom: 0;
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }
  }
}

.multiselect-tooltip-line {
  text-transform: capitalize;
  margin: 0;
}
