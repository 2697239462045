
.payments-container {
  position: relative;
  max-width: 720px;
}

.payment-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #80808059;
  z-index: 1000;
}
