@import '../../../../../../theme/variables.scss';

.story-item {
  cursor: pointer;
    .ring-wrapper {
      position: relative;
      width: 120px;
      height: 120px;
    }
    .image-round {
      border-radius: 50%;
      width: 110px; 
      height: 110px; 
      position: absolute; 
      left: 50%; 
      top: 50%; 
      transform: translate(-50%, -50%);
      &.solid{
        border: 1px solid #C6C6C5;
        border-width: 2px 2px 2px 2px;
      }
      &.dashed{
        border: 1px dashed #C6C6C5;
        border-width: 2px 2px 2px 2px;
      }
    }
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
    .image-item{
      border-radius: 50%;
      border: 3px solid white;
      width: 104px; 
      height: 104px; 
      position: absolute; 
      left: 50%; 
      top: 50%; 
      transform: translate(-50%, -50%); 
      overflow: hidden;
      .icon-edit-pencil{
        font-size: 13px;
        position: absolute;
        right: 15px;
        color: #C7C7C7;
        left: 50%; 
        top: 50%; 
        transform: translate(-50%, -50%);
        display: none; 
      }
    }
    .item-no-image{
      background-color: #D8D8D8; 
      background-position: 50% 50%;  
      background-size: cover; 
      height: 100%;
      width: 100%;
    }
    .text-item {
      text-align: center;
      left: 50%;
      color:#C7C7C7;
      transform: translateX(-50%);
      margin-top: 10px; 
      width: 130px; 
      font-size: 12px; 
      position: relative; 
      font-family: $secondaryFontFamily;

      &.selected{
        color: #06BB72;
      }
    }
  } 