@import '../../../../theme/variables.scss';

.no-channel-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.48);

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: -10px 21px 21px 0 rgba(50, 51, 54, 0.48);
        background-color: white;
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);
        overflow: initial;
        font-family: $secondaryFontFamily;
        background-color: #FFFFFF;
    }
    .modal-size-popup-intro {
        width: 460px;
        height: 443px;
    }
    .modal-size-popup-migration {
        width: 616px;
        height: 328px;
    }
    .popup-intro {      
        &__box {
            margin-top: 52px;
        }
        &__title {
            height: 29px;
            width: 300px;
            color: #3D3D3D;
            font-family: $secondaryFontFamily;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 36px;
            text-align: center;
            margin-left: 80px;
            span{
                color: #EE2E3D;
            }
            &.second-title{
                margin-top: -30px;
            }
        }
        &__sub-text {
            margin: 14px 0 0 80px;
            height: 48px;
            width: 300px;
            opacity: 0.5;
            color: #4A4A4A;
            font-family: $secondaryFontFamily;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.3px;
            line-height: 24px;
            text-align: center;
        }
        &__channel-box {
            box-sizing: border-box;
            height: 90px;
            width: 340px;
            border: 1px solid #DFDFDF;
            border-radius: 3px;
            margin: 24px 0 0 60px;
            img{
                margin: 25px 0 0 40px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
            .channel-box{
                font-family: $secondaryFontFamily;
                color: #4A4A4A;
                letter-spacing: 0;
                line-height: 14px;
                &__name{
                    margin: -44px 0 0 96px;
                    font-size: 16px;
                    font-weight: bold;
                }
                &__url{
                    margin: 3px 0 0 96px;
                    opacity: 0.51;
                    font-size: 14px;
                }
            }

        }
        &__attach-to-channel-btn {
            height: 44px;
            width: 232px;
            border-radius: 100px;
            background-color: #EE2E3D;
            margin: 39px 0 0 115px;
            color: #FFFFFF;
            font-family: $secondaryFontFamily;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-align: center;
            cursor: pointer;
            span {
                margin-top: 14px;
                margin-left: -84px;
                position: absolute;
            }
        }
        &__skip-btn {
            height: 16px;
            width: 140px;
            color: #FF0000;
            font-family: $secondaryFontFamily;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-align: center;
            margin: 16px 0 0 160px;
            cursor: pointer;
        }
    }
    .popup-migration {
        &__title {
            margin: 52px 0 0 158px; 
            height: 19px;
            width: 300px;
            color: #4A4A4A;
            font-family: $secondaryFontFamily;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 19px;
            text-align: center;
            span{
                color: #06bb72;
            }
        }
        &__gif{
            position: absolute;
            margin: 0 0 0 88px;
            z-index: 2;
        }
        &__trasfer-box {
            position: absolute;
            width:140px;
            height: 79px;
            margin: 10px 0 0 238px;

            &__current {
                position: absolute;
                height: 60px;
                width: 140px;
                color: #4A4A4A;
                font-family: $secondaryFontFamily;
                font-size: 50px;
                letter-spacing: 0;
                text-align: center;
            }
            &__total{
                position: absolute;
                margin: 115px 0 0 0;
                height: 19px;
                width: 140px;
                opacity: 0.5;
                color: #4A4A4A;
                font-family: $secondaryFontFamily;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 23px;
                text-align: center; 
            }
        }
        &__error{
            color: #f13346;
            font-size: 12px;
            margin: 200px 0 0 200px;
        }
    }
}