.current-payments-container{
  width: 729px;
  height: 232px;
  box-shadow: 0px 11px 21px 0 #e3e3e4;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  .container-header{
    width: 729px;
    height: 38px;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
      span{
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2px;
      color: #a4a4a4;
      padding-left: 35px;
      }
  }
  .container-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:35px;
    line-height: 2;
    font-size: 14px;
    width:80%;
    .left-section{
      width: 300px;
    }
    .right-section{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .date-text{
        color:#a3a3a3;
        font-size: 14px;
        margin-right: 5px;
      }
      .date{
        font-size: 14px;
        color:#4a4a4a;
        font-weight: bold;
      }
      .action{
        height: 28px;
        font-size: 12px;
        font-weight: normal;
        line-height: 2.25;
        color: #06bb72;
        position: relative;
        cursor: pointer;
        display: flex;
        &.cancel-plan-danger{
          color: red;
          &::after{
            content: " ";
            font-size: 14px;
            margin: -2px 5px;
            height: 4px;
            width: 4px;
            border-top: 1px solid red;
            border-left: 1px solid red;
            display: flex;
            align-items: center;
            align-self: center;
            flex-direction: row;
            transform: rotate(133deg);
            align-content: center;
          }
        }
        a {
          text-decoration: none;
          color:#06bb72;
        }
        &::after{
          content: " ";
          font-size: 14px;
          margin: -2px 5px;
          height: 4px;
          width: 4px;
          border-top: 1px solid #06bb72;
          border-left: 1px solid #06bb72;
          display: flex;
          align-items: center;
          align-self: center;
          flex-direction: row;
          transform: rotate(133deg);
          align-content: center;
        }
      }
      
      .action-empty{
        height: 28px;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.25;
        letter-spacing: normal;
        color: #6432ff;
        position: relative;
        cursor: pointer;
      }
    }
  }
}

