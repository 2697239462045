@import "./variables";

// Lato 2
@font-face {
  font-family: 'LatoLatin';
  src: url('fonts/lato/LatoLatin-Light.eot');
  src: url('fonts/lato/LatoLatin-Light.eot?#iefix') format('embedded-opentype'), url('fonts/lato/LatoLatin-Light.woff2') format('woff2'), url('fonts/lato/LatoLatin-Light.woff') format('woff'), url('fonts/lato/LatoLatin-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'LatoLatin';
  src: url('fonts/lato/LatoLatin-LightItalic.eot');
  src: url('fonts/lato/LatoLatin-LightItalic.eot?#iefix') format('embedded-opentype'), url('fonts/lato/LatoLatin-LightItalic.woff2') format('woff2'), url('fonts/lato/LatoLatin-LightItalic.woff') format('woff'), url('fonts/lato/LatoLatin-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'LatoLatin';
  src: url('fonts/lato/LatoLatin-Regular.eot');
  src: url('fonts/lato/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/lato/LatoLatin-Regular.woff2') format('woff2'), url('fonts/lato/LatoLatin-Regular.woff') format('woff'), url('fonts/lato/LatoLatin-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'LatoLatin';
  src: url('fonts/lato/LatoLatin-Italic.eot');
  src: url('fonts/lato/LatoLatin-Italic.eot?#iefix') format('embedded-opentype'), url('fonts/lato/LatoLatin-Italic.woff2') format('woff2'), url('fonts/lato/LatoLatin-Italic.woff') format('woff'), url('fonts/lato/LatoLatin-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'LatoLatin';
  src: url('fonts/lato/Lato-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/roboto/Roboto-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/roboto/Roboto-SemiBold.ttf') format('truetype');
  font-style: bold;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-style: bolder;
  font-weight: 600;
}

i {
  font-style: normal;
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?ym0myv') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ym0myv') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ym0myv') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ym0myv##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ic {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icomoon-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-expiration-date {
  &:before {
    content: $icon-expiration-date; 
  }
}
.icon-auto-poll {
  &:before {
    content: $icon-auto-poll; 
  }
}
.icon-delete-outlined {
  &:before {
    content: $icon-delete-outlined; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-file-download {
  &:before {
    content: $icon-file-download; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-light {
  &:before {
    content: $icon-light; 
  }
}
.icon-calendar-filled {
  &:before {
    content: $icon-calendar-filled; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-custom {
  &:before {
    content: $icon-custom; 
  }
}
.icon-long-curvy-arrow {
  &:before {
    content: $icon-long-curvy-arrow;     
    color: #505050;
  }
}
.icon-close-popup {
  &:before {
    content: $icon-close-popup; 
  }
}
.icon-story-thin {
  &:before {
    content: $icon-story-thin;     
    color: #4a4a4a;
  }
}
.icon-alert {
  &:before {
    content: $icon-alert;     
    color: #ffc300;
  }
}
.icon-alert-new {
  &:before {
    content: $icon-alert-new;     
    color: #fff;
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-edit-pencil {
  &:before {
    content: $icon-edit-pencil; 
  }
}
.icon-view {
  &:before {
    content: $icon-view; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-user-profile {
  &:before {
    content: $icon-user-profile; 
  }
}
.icon-countdown {
  &:before {
    content: $icon-countdown; 
  }
}
.icon-gallery {
  &:before {
    content: $icon-gallery; 
  }
}
.icon-personality {
  &:before {
    content: $icon-personality; 
  }
}
.icon-poll {
  &:before {
    content: $icon-poll; 
  }
}
.icon-story {
  &:before {
    content: $icon-story; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-embed {
  &:before {
    content: $icon-embed;     
    color: #505050;
  }
}
.icon-Lock {
  &:before {
    content: $icon-Lock;     
    color: #68696b;
  }
}
.icon-Iphone {
  &:before {
    content: $icon-Iphone;     
    color: #68696b;
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-text-l {
  &:before {
    content: $icon-text-l; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-apester {
  &:before {
    content: $icon-apester; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-DrowDown {
  &:before {
    content: $icon-DrowDown; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-inative {
  &:before {
    content: $icon-inative;     
    color: #505050;
  }
}
.icon-programmatic {
  &:before {
    content: $icon-programmatic;     
    color: #505050;
  }
}
.icon-csv {
  &:before {
    content: $icon-csv;     
    color: #505050;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;     
    color: #505050;
  }
}
.icon-performances {
  &:before {
    content: $icon-performances;     
    color: #505050;
  }
}
.icon-editorial {
  &:before {
    content: $icon-editorial;     
    color: #505050;
  }
}