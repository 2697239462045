@import '../../../theme/variables.scss';

.custom-popup {
  color: $neutral900;
  font-family: $secondaryFontFamily;

  .MuiPaper-root {
    box-shadow: none;
    border-radius: 8px;
    width: 460px;
  }

  &-container {
    padding: 24px;
    padding-bottom: 0;
  }

  &-error-message {
    @include baseMediumRegular;
    height: 24px;
  }

  &-title {
    padding: 0;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  &-content {
    padding: 0;
    margin-top: 13px;
  }

  &-description {
    @include baseMediumRegular;
    color: inherit;
    b {
      font-weight: 500;
    }
  }

  &-actions {
    padding: 0;
    margin-top: 34px;

    .approve, .cancel {
      height: 40px;
      padding: 8px 20px;
      min-width: 100px;
      font-size: 14px;
      line-height: 24px;
    }

    .approve {
      margin-left: 24px;
    }
  }
}
