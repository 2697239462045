.upload-image-container {
  position: relative;
  border: 1px dashed #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  .displayed-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  &:hover {
    .image-placeholder,
    .icon-close-popup,
    .subtitle,
    .shadow {
      opacity: 1 !important;
    }

    .shadow {
      background: rgba(0, 0, 0, 0.6);
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.6) 0, rgba(0, 0, 0, 0.6) 100%);
    }

    .image-placeholder {
      span {
        color: #cccccc;
      }
    }
  }

  .shadow {
    position: absolute;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: all 0.4s ease;
  }

  .image-placeholder {
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: opacity 0.2s ease;
    color: #3d3d3d;

    .subtitle {
      opacity: 0;
      transition: inherit;
    }

    .icon-image {
      color: #cccccc;
    }
  }

  .icon-close-popup {
    position: absolute;
    opacity: 0;
    color: #d9d9d9;
    z-index: 5;
  }

  .input-wrapper {
    z-index: 3;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;

    .input-file {
      visibility: hidden;
    }
  }

  &.uploaded {
    border-color: transparent;

    .image-placeholder {
      opacity: 0;
    }
  }

  &.top-image {
    height: 228px;

    .title {
      margin-bottom: 11px;
      font-size: 12px;
      font-weight: 700;
    }

    .subtitle {
      font-size: 11px;
    }

    .icon-image {
      font-size: 40px;
      margin-bottom: 19px;
    }

    .icon-close-popup {
      top: 5px;
      right: 5px;
    }
  }

  &.category-image {
    height: 56px;
    width: 116px;

    .image-placeholder {
      margin-top: 8px;
    }

    .subtitle {
      font-size: 10px;
      opacity: 0;
    }

    .icon-image {
      font-size: 16px;
      margin-bottom: 3px;
    }

    .icon-close-popup {
      font-size: 10px;
      top: 3px;
      right: 3px;
    }
  }

  &.invalid {
    border-color: #eb323c;
  }
}
