@import './theme/variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $primaryFontFamily;
}

.MuiListItemText-multiline {
  margin-top: 0;
  margin-bottom: 0;
}

.MuiButton-root {
  text-transform: none;
}

.MuiTypography-root, .MuiButton-root {
  letter-spacing: normal;
}

.MuiPaper-root {
  box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
  border-radius: 4px;
  border: 1px solid $neutral100;
}

input, .MuiInputBase-input {
  &::-webkit-input-placeholder {
    color: $neutral500;
    opacity: 1;
    font-size: inherit;
  }
}

button {
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    cursor: default;
  }

  &[class*="contained-"], &[class*="outlined-"] {
    border: 1px solid transparent;
    border-radius: 4px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    font-weight: 400;
  }

  &[class*="contained-"] {
    color: white;
  }

  &[class*="outlined-"] {
    background-color: transparent;
    color: #404040;
    border-color: #CCCCCC;
  }

  &.contained-primary {
    font-size: 16px;
    line-height: 24px;
    column-gap: 14px;
    background-color: $primary500;
    padding: 12px 24px;
    font-family: $secondaryFontFamily;
    color: white;

    &:disabled {
      background-color: $neutral100;
      color: $neutral400;
    }

    &:not(:disabled):hover {
      background-color: $primary600;
    }
  }

  &.outlined-secondary {
    line-height: 24px;
    background-color: transparent;
    padding: 8px 20px;
    font-family: $secondaryFontFamily;
    border: 1px solid $neutral200;
    color: $neutral900;
    transition: all 0.3s ease;

    &:disabled {
      color: $neutral400;
    }

    &:not(:disabled):hover {
      background-color: $neutral50;
      border-color: $neutral200
    }
  }

  &.contained-settings, &.outlined-settings {
    font-family: $primaryFontFamily;
    text-transform: uppercase;
    padding: 8px 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 2px;
    column-gap: 8px;
  }

  &.contained-settings {
    background-color: #1FBA75;

    &:disabled {
      background-color: #D9D9D9;
    }

    &:not(:disabled):hover {
      background-color: #1CA769;
    }
  }

  &.outlined-settings {
    &:not(:disabled):hover {
      background-color: #F5F5F5;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.MuiTableCell-stickyHeader {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #fafafa;
}

.MuiInputBase-root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: $primaryFontFamily;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  border-color: #d2d2d7
}
