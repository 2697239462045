@import '../../../theme/variables.scss';

.custom-tooltip {
  padding: 8px 16px;
  background-color: $neutral800;
  @include baseSmallRegular;
  text-align: center;
  max-width: 200px;
}

.custom-arrow {
  font-size: 10px;
  color: $neutral800;
}

.custom-tooltip-children {
  width: fit-content;
}
