@import '../../../../../../theme/variables.scss';

.add-branded-interaction-pop-up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.48);
    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 25px 25px 0 0;
      width: 608px;
      height: 257px;
      box-shadow: 0 9px 21px 0 rgba(0, 0, 0, 0.51);
      font-family: $secondaryFontFamily;
      .image-round{
        border: 1px solid #C6C6C5;
      }
      .close-button {
        float: left;
        font-size: 13px;
        border: none;
        align-self: flex-end;
        cursor: pointer;
        &:focus {
          outline: 0;
        }
      }
      .interaction-image{
        position: absolute;
        margin: 42px 0 0 34px;
      }
      .modal-interaction-form{
        position: absolute;
        height: 109px;
        width: 377px;
        .interaction-header{
          position: absolute;
          margin: 44px 0 0 188px;
          font-size: 22px;
          width: 120px;
        }
        .interaction-label{
          position: absolute;
          margin: 85px 0 153px 188px;
          width: 120px;
          width: 86px;
          color: #4A4A4A;
          font-family: $secondaryFontFamily;
          font-size: 14px;
        }
        .interaction-inbox{
          position: absolute;
          margin: 112px 0 0 188px;
          width: 377px;
          height: 41px;
          z-index:2;
  
          .inbox-wrapper{
            width: 100%;
            .inbox-wrapper-container {
              border: solid 1px rgba(228, 228, 228, 100);
              border-radius: 3px;
              height: 39px;
              text-align: initial;
              display: grid;
              grid-template-columns: 1fr auto;
  
              &.interaction-error {
                border: solid 1px red;
              }
  
              .interaction-error-message{
                position: absolute;
                margin: -25px 0 0 320px;
                height: 15px;
                width: 60px;
                transform: rotate(360deg);
                color: #EE2E3D;
                font-family: $secondaryFontFamily;
                font-size: 12px;
                letter-spacing: 0.23px;
                line-height: 15px;
              }
      
              input{
                padding: 10px 0px 10px 10px;
                border: none;
                color: #68696B;
                font-family: $secondaryFontFamily;
                font-size: 14px;
                letter-spacing: 0.26px;
                line-height: 17px;
                &::placeholder{
                    color: #d8d8d8;
                }
                &:focus {
                  outline: 0;
                }
              }
              .action-label-add{
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0.3px;
                cursor: pointer;
                color: #06BB72;
                text-decoration: none;
                padding: 12px 10px 10px 10px;
              }
              .icon-trash {
                float: right;
                position: absolute;
                right: 12px;
                font-size: 24px;
                color: #C7C7C7;
                margin-top: 7px;
                cursor: pointer;
              }
            }
          }
        }
        .preview-button{
          position: absolute;      
          align-self: flex-end;
          font-size: 14px;
          width: 79px;
          height: 24px;
          border:none; 
          cursor: pointer;   
          color: #9E9E9E;
          .preview-text{
            margin: 176px 0 0 320px;
            position: absolute;
            text-decoration: underline;
            color: #9E9E9E;
          }
          .icon-view{
            margin: 176px 0 0 296px;
            position: absolute;
            font-size: 18px;
          }
        }
        .interaction-save-button{
          position: absolute;
          margin: 177px 0 0 434px;
          align-self: flex-end;
          font-size: 12px;
          width: 132px;
          height: 40px;
          border-radius: 2px;
          background-color: #06BB72;
          color: #ffffff;
          cursor: pointer;
  
          &:focus {
            outline: 0;
          }
          &.disabled{
            background-color: #CACACA;
            cursor: not-allowed;
          }
        }
      }
    }
  }