.unit-performance {
  .form-section {
    grid-row-gap: 0 !important;

    &> div:nth-child(2n+1):nth-last-child(-n+2),
    &> div:nth-child(2n+1):nth-last-child(-n+2) ~ div {
       padding-top: 30px;
    }
  }
}
