@import '../../../theme/variables.scss';

.avatar-wrapper {
  position: relative;
  display: flex;
  .picture {
    background-color: #ffffff !important;
    border-radius: 50%;
  }

  .avatar-name {
    text-align: center;
    margin-top: 25px;
    font-size: 14px;
    color: $console-title-gray;
  }

  .icon-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 50%;
    color: rgba(white, 0.8);
    opacity: 0;
    transition: opacity 0.2s;

    .user-avatar-upload {
      cursor: pointer;
      position: absolute;
      height: 100%;
      width: 100%;

      .input-file {
        visibility: hidden;
      }

      .icon {
        font-weight: 100;
        position: absolute;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        font-size: 30px;
        top: calc(50% - 15px);
      }
    }
  }

  &.editable{
    &.empty, &:hover{
      .icon-container {
        background: rgba(61, 61, 61, 0.8) !important;
        opacity: 1;
      }    
    }
  }
}
