@import '../../../../theme/variables.scss';

.url-exclude-list {
  grid-column: 1 / span 2;
  padding: 8px 0;
  [class=url-list-item-wrpper]:last-child {
    border-bottom: 1px solid rgb(232, 232, 232);
  }
}

.create-form.ads-settings-form {
  display: flex;
  flex-direction: column;
  gap: $settings-sections-padding;
  .outlined-secondary {
    padding: 7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  .field-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: $settings-sections-gap;
    &.companion-images {
      flex-wrap: nowrap;
    }
    .companion-backfill-link-description {
      flex: 1 0 100%;
    }
    .companion-backfill-link-field {
      flex: 1 1 auto;
    }
    .toggle-form-control {
      flex: 1 0 100%;
    }
    .pubstack-tag-input {
      flex: 1 1 auto;
    }
    .helper-text {
      font-size: 12px;
      color: $neutral500;
    }
    .field-error .helper-text {
      color: $primary600;
    }
    label {
      padding: $settings-sections-label-padding 0;
    }
  }
}

.exclude-ads-label {
  font-size: 14px;
  line-height: 16px;
  position: relative;
  bottom: 8px;
}
.tracking-pixel-wrapper {
  flex: 1;
  display: flex;
}
.pubstack-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: $settings-sections-padding;  
}

.companion-placeholder-toggle {
  grid-column: 1 / 3;
}

.companion-settings {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .companion-image {
      flex: 0 1 50%;
    }
    .upload-image-container {
      aspect-ratio: 300 / 250;
      height: auto;
    }


    .error-message {
      height: 0;
      overflow: hidden;
      position: static;
  
      &.show {
        height: 18px;
        padding-top: 8px;
      }
    } 
}
