@import '../../../theme/variables.scss';

.ape-snack-bar {
  color: white;
  font-family: $secondaryFontFamily;
  height: 52px;

  & > div {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border: none;
    background-color: $neutral800;
    border-radius: 4px;
    padding: 14px 24px;
    box-sizing: border-box;
    min-width: auto;
  }

  .MuiSnackbarContent-message {
    padding: 0;
  }

  &.open {
    bottom: 32px;
  }

  .message {
    display: grid;
    grid-auto-flow: column;
    width: fit-content;
    column-gap: 11px;
    align-items: center;
    @include baseRegular;
    letter-spacing: normal;

    b {
      font-weight: 500;
    }

    .ic {
      font-size: 20px;
    }

    svg {
      color: white;
    }

    .close-button {
      display: grid;
      grid-template-columns: 22px 1px 16px;
      margin-left: 11px;

      .vertical-separator {
        height: 100%;
        width: 1px;
        background-color: $neutral500;
      }

      svg {
        cursor: pointer;
      }
    }
  }
}
