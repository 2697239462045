@import '../../../theme/variables.scss';

$smallFontSize: 13px;
$mediumFontSize: 14px;
$largeFontSize: 16px;
$smallIconSize: 16px;
$mediumIconSize: 16px;
$largeIconSize: 20px;
$multiselectCheckboxSize: 28px;
$smallButtonHeight: 32px;
$mediumButtonHeight: 40px;
$largeButtonHeight: 48px;

.custom-select-menu {
  color: $neutral900;
  font-family: $secondaryFontFamily;

  &.custom-menu-popper .MuiPaper-root {
    margin-top: 25px;
  }

  .MuiInputBase-root {
    &.Mui-focused fieldset, fieldset {
      border: none;
    }
  }

  .select-search-field {
    width: 100%;
    .field-icon {
      font-size: 20px;
    }

    input {
      font-weight: 300;
      color: $neutral900;
      line-height: 24px;
      font-size: 14px;
      width: 100%;
    }

    .MuiInputBase-root {
      padding: 0;
    }

    .MuiInputBase-root,
    input,
    .MuiInput-underline:after,
    .MuiInput-underline::before {
      border-bottom: none;
    }
  }

  .list-item-button {
    padding: 0 16px;
    margin: 4px 0;
    height: 40px;

    &:not(.search-item) {
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .search-item {
    padding: 0 10px 0 16px;
  }

  .MuiPaper-root {
    margin-top: 15px;
    max-height: 228px;
  }

  &.small li {
    height: $smallButtonHeight;
    font-size: $smallFontSize;
    svg {
      font-size: $smallIconSize;
    }
  }

  &.medium li {
    height: $mediumButtonHeight;
    font-size: $mediumFontSize;
    svg {
      font-size: $mediumIconSize;
    }
  }

  &.large li {
    height: $largeButtonHeight;
    font-size: $largeFontSize;
    svg {
      font-size: $largeIconSize;
    }
  }
}

.single-select {
  li {
    display: grid;
    text-transform: capitalize;
    column-gap: 12px;
    align-items: center;
    color: $neutral900;
    font-weight: 300;

    &.selected {
      font-weight: 400;
    }

    &.disabled-item {
      pointer-events: none;
      cursor: default;
      color: $neutral300;
    }

    svg {
      vertical-align: sub;
      font-weight: 300;
    }

    &.Mui-selected {
      background-color: transparent;
      font-weight: 500;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  &.small {
    li {
      grid-template-columns: $smallIconSize 1fr;
    }
  }

  &.medium {
    li {
      grid-template-columns: $mediumIconSize 1fr;
    }
  }

  &.large {
    li {
      grid-template-columns: $largeIconSize 1fr;
    }
  }
}

.multiselect-menu-popover {
  .MuiPaper-root {
    margin-top: 35px;
    margin-left: 5px;
    max-height: 297px;
    overflow: hidden;
    width: 220px;
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .multiselect-item-label {
    color: $neutral900;
    width: calc(100% - #{$multiselectCheckboxSize} - 9px);

    p {
      margin: 0;
    }

    .primary-text {
      @include baseMediumRegular;
    }

    &.multiline {
      display: flex;
      flex-direction: column;

      .primary-text {
        @include baseSmallRegular;
      }

      .secondary-text {
        @include baseExtraSmallRegular;
        color: $neutral500;
      }
    } 
  }

  .divider {
    background-color: $neutral100;
  }

  .list-item-button {
    .multiselect-item-control {
      height: 100%;
      width: 100%;
    }
  }

  .options-list {
    overflow: auto;

    // Avoid double border incase of empty list.
    &:has(ul):has(li) {
      border-bottom: solid 1px $neutral100;
    }
  
    &:has(.selected-list li):has(.unselected-list li) .selected-list {
      border-bottom: solid 1px $neutral100;
    }
  }

  .multiselect-dropdown-footer {
    height: 52px;
    align-items: center;
    justify-content: flex-end;
    padding: 4px 16px;
    display: grid;
    grid-column-gap: 12px;
    grid-auto-flow: column;
  
    .contained-primary {
      padding: 8px 12px;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
