.modal-container-text{
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  text-align: start;
  height: 512px;

  .text{
    display: flex;
    font-size: 12px;
    line-height: 1.62;
    letter-spacing: 1.72px;
    color: #505050;
    flex-direction: column;
    padding: 20px 40px;
    span {
      font-weight: bold;
      text-transform: capitalize;
      text-decoration: underline;
      font-size: 14px;
    }
  }
}
    .modal-footer{
      display: flex;
      padding: 15px 15px 15px 40px;
    }