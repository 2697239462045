@import '../../../../theme/variables.scss';

.pixels-form {
  padding: $settings-sections-padding;

  .fields-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 10px;
  }


  &.fb {
    .event-field {
      display: inline-block;
      width: 50%;
  }
  .fields-list {
    display: grid;
    row-gap: 10px;
  }
}

.input-field {
  display: inline-block;
  width: 50%;
  input, .input {
    width: 63%;
    padding: 1px 2px;
  }
}

  .field-array {
    list-style-type: none;
    padding-left: 0;
    row-gap: 10px;

    .add-icon {
      font-size: 12px;
    }

    .pixel-event-row {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-bottom: 30px;
      .event-general {
        align-items: center;
        display: flex;
      }
    }

    .add-button {
      border-radius: 10px;
      font-size: 14px;
      padding: 2px 10px;
      margin-right: 60px;
      border: 1px solid #4a4a4a;
      cursor: pointer;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      width: 83px;
      user-select: none;
      .fb-event-row &{
        display: inline-block;
      }
    }

    .remove-button {
      color: #4a4a4a;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
