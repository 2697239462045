@import '../../theme/variables.scss';

$breadcrumbs-height: 58px;
$select-icon-size: 12px;

.settings-layout {
  height: 100vh;
  overflow: hidden;
  .skeleton-grid {
    height: calc(100% - #{$header-height + $breadcrumbs-height});
  }
}

.settings-layout:has(#header-section #notification) {
  .breadcrumbs-container {
    margin-top: calc(#{$header-height + $trial-notification-height});
  }
  .skeleton-grid {
    height: calc(100% - #{$header-height + $trial-notification-height + $breadcrumbs-height});
  }
}

.breadcrumbs-container {
  margin-top: $header-height;
  height: $breadcrumbs-height;
  display: flex;
  align-items: center;
  padding: 0 46px;
}

.user-data {
  width: 100%;
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  padding-top: 30px;
  width: 70%;

  .number {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    color: #06bb72;
  }

  .data-title {
    font-size: 11px;
    text-align: center;
    color: #4a4a4a;
    align-self: baseline;
    padding-top: 2px;
  }

  .line {
    border-right: solid 1px #d8d8d8;
  }
}

.number-of-members {
  color: #4a4a4a;
  line-height: 1.14;
  font-size: 14px;
  font-weight: normal;
  margin-top: 8px;
}

.autocomplete-search-wrapper {
  display: flex;
  justify-content: space-between;

  .search-field {
    width: 379px;
  }

  .outlined-secondary {
    .button-label {
      font-size: 14px;
      line-height: 24px;
      display: grid;
      grid-auto-flow: column;
      column-gap: 8px;
    }
  }
}

.table-wrapper {
  padding: 0 $settings-sections-padding $settings-sections-padding;

  .select-column-field.small {
    .custom-select-field {
      font-size: 12px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 15px;
    }

    .custom-select-icon {
      height: $select-icon-size;
      width: $select-icon-size;
      font-size: $select-icon-size;
      top: 1px;
      right: 2px;
    }

    .ellipsis-tooltip {
      width: fit-content;
    }
  }

  .actions-button {
    svg {
      color: $neutral500;
    }
    &:hover {
      background-color: transparent;
    }
  }

  .name-column {
    max-width: 180px;
    .primary {
      @include baseSmallSemiBold;
    }
    .secondary {
      text-transform: lowercase;
    }
    a:-webkit-any-link {
      text-decoration: none;
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .header-row {
    background-color: $neutral50;
    border-bottom: 2px solid #ffffff;
    height: 32px;
    th {
      border-bottom: none;
      @include baseExtraSmallSemiBold;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .body-row {
    height: 56px;
    td {
      border-color: $neutral100;
      @include baseExtraSmallRegular;
      text-transform: capitalize;
    }
  }

  .header-row,
  .body-row {
    th, td {
      color: $neutral700;
      font-family: $secondaryFontFamily;
      &:not(.checkbox-column) {
        padding-right: 10px;
      }
      &.checkbox-column {
        padding: 0 8px;
        width: 28px;
      }
    }
  }
}

.tooltip-line {
  margin: 0;
}
