@import '../../theme/variables.scss';

.security-settings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 14px;
  padding: $settings-sections-padding $settings-sections-padding 0;

  .password-section {
    display: grid;
    grid-template-rows: 1fr 1fr;

    label {
      color: #4a4a4a;
    }
    .change-button {
      border: none;
      font-size: 12px;
      line-height: 2.25;
      letter-spacing: normal;
      color: #06bb72;
      cursor: pointer;

      .icon {
        vertical-align: middle;
        padding-left: 5px;
      }
    }
  }

  .auth-section {
    display: grid;
    grid-template-rows: 1fr 1fr;

    label {
      color: #4a4a4a;
    }
    .mfa-buttons {
      display: flex;

      .mfa-button {
        border: none;
        font-size: 12px;
        line-height: 2.25;
        letter-spacing: normal;
        color: #a3a3a3;
        cursor: pointer;

        .icon {
          vertical-align: middle;
          padding-left: 2px;
        }
      }
    }
  }
}
