@import '../../../../theme/variables.scss';

.input-label {
  font-family: $secondaryFontFamily;
  font-size: 14px;
  line-height: 16px;
  margin-left: 14px;
  position: relative;
  bottom: 8px;
}

.url-error {
  margin-left: 26px;
  color: red;
}
.url-list-item-wrpper {
  position: relative;
  border-top: solid 1px #e8e8e8;
  &:hover {
    background: #f0f0f0 !important;
  }
  .collapse-content {
    display: flex;
    flex-direction: column;
    height: 300px;
    > div {
      margin: 0 auto;
      width: 75%;
    }
    .url-error {
      position: relative;
      display: block;
      top: 60px;
      left: 150px;
      font-size: 12px;
    }
    .inputs-fields {
      position: relative;
      top: 50px;
      display: flex;
      flex-direction: row;
      > div,
      button {
        flex: 1 auto;
      }
      button {
        margin-right: 50px;
      }
    }
  }
}
.url-list-item {
  width: 100%;
  height: 80px;
  padding: 16px 37px;
  border-top: 1px solid $neutral100;
  &:last-child {
    border-bottom: 1px solid $neutral100;
  }

  .content {
    padding: 20px 16px 20px 80px;
    position: relative;
    font-size: 14px;
    color: #3d3d3d;
    overflow-wrap: break-word;
  }

  .avatar {
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
    height: 50px;
    width: 50px;
  }

  &.disabled {
    filter: opacity(0.3) grayscale(1);
  }

  .delete-url-icon {
    position: absolute;
    top: 22px;
    left: -12px;
    width: 10px;
    height: 10px;
    font-size: 10px;
    visibility: hidden;
    color: #505050;
  }

  &:hover {
    background: #F0F0F0 !important;
    
    .delete-url-icon {
      visibility: visible;
    }
  }
}
