@import '../../../theme/variables.scss';

.custom-checkbox {
  margin: 0;

  .MuiCheckbox-root {
    box-sizing: border-box;
    height: 28px;
    width: 28px;
    padding: 4px;
    color: $neutral500;
    &:hover {
      background-color: rgba(30, 30, 55, 0.04);
    }
    &.has-label.end {
      margin-right: 9px;
    }
    &.has-label.start {
      margin-left: 9px;
    }
    &.Mui-checked:hover {
      color: $primary600;
    }
  }

  .Mui-checked {
    color: $primary500;
    &.Mui-disabled rect {
      fill: $primary100;
    }
  }

  :not(.Mui-checked) {
    &.Mui-disabled rect {
      stroke: $neutral100;
      fill: $neutral50;
    }
  }
}
