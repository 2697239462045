@import '../../../../../theme/variables.scss';

$gapBetweenSections: 36px;

.form-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .bold {
    font-weight: bold;
  }

  .plan-name-container {
    display: flex;
    align-items: center;
  }
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
  margin-top: $gapBetweenSections;
}

.pricing-selection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .pricing {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 340px;
    align-items: center;
    margin-top: 10px;
    &:not(:last-child) {
      margin-top: 16px;
    }
    .pricing-title {
      height: 27px;
      font-size: 14px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      line-height: 1.93;
      letter-spacing: normal;
      color: #5f5f5f;
      .pricing-discount-box {
        width: 124px;
        height: 27px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
        .title {
          font-size: 12px;
          line-height: normal;
          color: #00bc70;
          font-weight: normal;
          text-transform: initial;
        }
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      border: 1px solid green;
    }
  }
}

.checkmark {
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #06bb72;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.pricing {
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #06bb72;
    position: absolute;
    top: 2px;
  }
}

.plan-note {
  font-size: 14px;
  letter-spacing: 0.23px;
  color: #a3a3a3;
  margin-top: 8px;
  padding-left: 30px;
  line-height: 1.21;
  .plan-features {
    cursor: pointer;
    line-height: 1.57;
    color: #06bb72;
    text-decoration: underline;
    margin-left: 3px;
  }
}

.terms {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark-box {
  height: 12px;
  width: 12px;
  border: 1px solid #06bb72;
  transform: translateY(2px);
}

.terms input:checked ~ .checkmark-box {
  background-color: #fff;
  border: 1px solid #06bb72;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark-box:after {
  content: "";
  position: absolute;
  display: none;
}

.terms {
  input:checked ~ .checkmark-box:after {
    display: block;
  }
  .checkmark-box:after {
    width: 14px;
    height: 14px;
    background-color: #06bb72;
  }
  .checkmark-box:before {
    content: "";
    position: absolute;
    border-bottom: 2px solid white;
    z-index: 999;
    height: 3px;
    width: 7px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: translate(-50%, -65%) rotate(315deg);
    top: 50%;
    left: 50%;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  span::first-letter {
    text-transform: uppercase;
  }
  input {
    font-family: $secondaryFontFamily;
    border: none;
    border-bottom: 1px solid #e9e9e9;
    outline: none;
    transition: border 0.3s ease;
    font-size: 14px;
    font-weight: normal;
    color: #4a4a4a;
    padding: 4px 2px 4px 0;
    &:focus {
      border: none;
      border-bottom: 1px solid #b4b3b3;
      outline: none;
    }
    &.input-error {
      border-bottom: 1px solid red;
      color: red;
    }
  }
}

.input-title {
  font-size: 12px;
  color: #969696;
  margin-top: 4px;
}

.group-input-container {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  .input-container {
    width: 25%;
    input {
      width: 100%;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 30px;
}

.StripeElement {
  float: right;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  transition: border 0.3s ease;
  padding: 6px 2px 4px 0;
  width: 98%;
  color: green;
  input {
    // Add your base input styles here. For example:
    font-size: '16px';
    color: yellow;
  }
}

.StripeElement--focus {
  border-bottom: 1px solid #b4b3b3;
  font-size: 20px;
  color: red;

}

.StripeElement--invalid {
  border-bottom: 1px solid red;
  color: green;
}

.StripeElement--placeholder {
  color: green;
}

.ElementsApp .InputElement {
  background-color: blue;
}

.is-invalid {
  color: green;
}

.stripe-group-elem {
  display: flex;
  flex-direction: column;
  width: 30%;
  &:not(:last-child) {
    margin-right: 30px;
  }
  &:first-child {
    width: 40%;
  }
}

.payment-summary {
  display: flex;
  height: 49px;
  justify-content: space-between;
  border: 1px solid rgba(201, 201, 201, 0.62);
  border-radius: 3px;
  background-color: rgba(247, 247, 247, 0.62);
  margin-top: 40px;
  padding: 0px 16px 0 16px;
  .elem {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    color: #969696;
    align-items: center;
  }
}

.price-total {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #06bb72;
  .plan-type {
    text-transform: capitalize;
    color: #a3a3a3;
  }
}

.plan-term {
  display: flex;
}

.terms-title {
  font-size: 14px;
  color: #787878;
  z-index: 1;
  margin-left: 10px;
}
.purchase-and-terms-container {
  height: 40px;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  // should merge with Profile.scss
  .submit-button {
    justify-self: end;
    grid-column: 2;
  
    button {
      font-weight: bold;
      letter-spacing: 2.4px;
      color: #ffffff;
      font-size: 12px;
      width: 132px;
      height: 40px;
      font-family: $secondaryFontFamily;
      font-weight: bold;
      letter-spacing: 2.4px;
      border-radius: 3px;
      border: solid 1px #06bb72;
      background-color: #06bb72;
      transition: all 0.3s ease;
      text-transform: uppercase;
      cursor: pointer;
  
      &:disabled {
        background-color: #babdbc80;
        border: none;
        cursor: not-allowed;
      }
  
      &:hover:enabled {
        background-color: #07a767;
        color: white;
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.submit-section {
  display: flex;
  justify-content: space-between;
}

.open-terms {
  z-index: 10;
  cursor: pointer;
  color: #a3a3a3;
  text-decoration: underline;
}

.errors-container {
  height: 38px;
  display: flex;
  align-items: center;
  .error-stripe {
    color: red;
    font-size: 11px;
  }
}

.form-submission-error {
  color: red;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
