@import '../../../../theme/variables.scss';

.create-form.password-form {
  padding: 0;
  .inputs-container {
    display: grid;
    justify-content: center;
    grid-gap: 12px;
  }
  
  .subheader-title {
    font-family: $primaryFontFamily;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #505050;
    padding-bottom: 25px;
  }
  
  .input-field-wrapper.input-field {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    input {
      font-family: $primaryFontFamily;
      width: 231px;
      height: 43px;
      box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #f7f7f7;
      border: none;
      box-sizing: border-box;
      padding-left: 15px;
  
      &::placeholder {
        font-size: 12px;
        color: #505050;
      }
    }
  }
  
  .submit-container {
    padding-top: 20px;
    text-align: center;
  
    .submit-button {
      font-family: $primaryFontFamily;
      width: 231px;
      height: 43px;
      background-color: #06bb72;
      color: white;
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      border: none;
  
      &:focus {
        outline: 0;
      }
  
      &:disabled {
        border: 1px solid #e4e4e4;
        color: #969696;
        background-color: white;
      }
    }
  }  
}

.submit-container {
  padding-top: 20px;
  text-align: center;

  .general-button {
    font-family: $primaryFontFamily;
    width: 231px;
    height: 43px;
    background-color: #ffff;
    font-size: 12px;
    font-weight: bold;
    color: #06bb72;
    border: none;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.black {
      color: black;
    }
  }
} 
