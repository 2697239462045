.codes-container {
  display: inline-block;
  width: 40%;
  margin-top: 40px;
  .list-code {
    background-color: #fafafa;
    box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 120px;

    .item-code {
      float: left;
      font-size: 14px;
      color: #3d3d3d;
      padding: 18px 0 0 24px;
    }
  }
}
