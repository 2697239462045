.flat-button-wrapper {
  width: 180px;
  margin: 6px 20px;
  button {
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
    background-color: #FFFFFF;
    border-radius: 100px;
    line-height: 100%;
    width: 100%;
    height: 40px;
    font-size: 11px;
    font-weight: 400;
    color: '#505050';
    &:hover {
      background-color: #F9F9F9;
    }
  }
}
