@import '../../../../../theme/variables.scss';

.plan-form-container{
  width: 100%;
  position: relative;
  font-family: $secondaryFontFamily;
  font-style: normal;
  font-stretch: normal;
  &.box-shadow {
    border: 1px solid $neutral100;
  }
  .plan-form-title{
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    height: 38px;
    .title{
      opacity: 0.5;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 2px;
      color: #505050;
      margin-left: 30px;
      text-transform: uppercase;
    }
  }
}
