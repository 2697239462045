.authentication-container {
  height: 220px;
  width: 50%;
  position: absolute;
  margin-top: 125px;
  border-left: 1px solid rgba(80,80,80,0.50);
  left: 50%;

  &.active {
    height: 380px;
    opacity: 0;
    transform: translateY(-50px);
    animation: 0.3s linear 0s show forwards;
  }
}